import React, { useEffect, useState } from "react";
import newLogoDark from "../../../assets/images/logo/Ilumn Coloured Logo.png";

import { Row, Col, CardBody, Card, Container } from "reactstrap";

import { useDispatch } from "react-redux";

import { Link, useNavigate } from "react-router-dom";
import { EnableMFA } from "./EnableMFA";
import { ScanMFA } from "./ScanMFA";
import { SuccessMFA } from "./SuccessMFA";
import { authenticateLoginOtp, setLoadingState } from "../../../store/actions";
import {
  generateQro,
  validateQro,
  skipQro,
} from "../../../store/dashboard/orchestractor";

export const MultiFactorAuth = () => {
  const title = "Enable two-step Verification";

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [step, setStep] = useState(1);
  const [errorMsg, setErrorMsg] = useState("");
  const [errorWithGenerateQRO, setErrorWithGenerateQRO] = useState("");
  const [scannerImageBase64, setScannerImageBase64] = useState("");

  useEffect(() => {
    document.title = title;
    setStep(2);
  }, []);

  const handleSkipMFAClick = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    try {
      dispatch(setLoadingState(true));
      const response = await skipQro();
      if (response?.data && response?.data?.user) {
        dispatch(authenticateLoginOtp(response?.data, navigate));
        dispatch(setLoadingState(false));
      } else {
        setErrorWithGenerateQRO("Some error occur");
        dispatch(setLoadingState(false));
      }
    } catch (error) {
      console.error(error);
      setErrorWithGenerateQRO("Some error occur");
      dispatch(setLoadingState(false));
    }
  };
  const handleEnableMFAClick = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    try {
      dispatch(setLoadingState(true));
      const response = await generateQro();
      if (response && response?.status === 200 && response?.data) {
        setScannerImageBase64(response?.data || "");
        setStep(3);
        dispatch(setLoadingState(false));
      } else {
        setScannerImageBase64("");
        setErrorWithGenerateQRO("Some error occur");
        dispatch(setLoadingState(false));
      }
    } catch (error) {
      console.error(error);
      setScannerImageBase64("");
      setErrorWithGenerateQRO("Some error occur");
      dispatch(setLoadingState(false));
    }
  };
  const handleOTPVerifyClick = async (otpValue) => {
    try {
      dispatch(setLoadingState(true));
      const response = await validateQro({ otp: otpValue });
      if (response) {
        setStep(4);
        dispatch(setLoadingState(false));
      } else {
        setErrorMsg("OTP Incorrect/Expired, Please try again");
        dispatch(setLoadingState(false));
      }
    } catch (error) {
      console.error(error);
      setErrorMsg("Some error occur");
      dispatch(setLoadingState(false));
    }
  };

  return (
    <div className="account-pages my-4">
      <Container>
        <Row className="justify-content-center">
          <Col lg={6} md={8} xl={4} className="mt-3">
            <Card>
              <CardBody style={{ padding: 30 }}>
                <div>
                  <Link to="/">
                    <img src={newLogoDark} alt="" height="40" />
                  </Link>
                </div>
                {step === 1 && <div></div>}
                {step === 2 && (
                  <EnableMFA
                    title={title}
                    errorWithGenerateQRO={errorWithGenerateQRO}
                    handleEnableMFAClick={handleEnableMFAClick}
                    handleSkipMFAClick={handleSkipMFAClick}
                  />
                )}
                {step === 3 && (
                  <ScanMFA
                    scannerImageBase64={scannerImageBase64}
                    errorMsg={errorMsg}
                    setErrorMsg={setErrorMsg}
                    handleOTPVerifyClick={handleOTPVerifyClick}
                  />
                )}
                {step === 4 && <SuccessMFA />}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
