import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  MFA_ENABLED,
  OTP_ENABLED,
  AUTHENTICATE_LOGIN_OTP,
  SSO_CALLBACK,
} from "./actionTypes";

export const loginUser = (user, history) => {
  return {
    type: LOGIN_USER,
    payload: { user, history },
  };
};

export const setMFAEnableFlow = (payload) => {
  return {
    type: MFA_ENABLED,
    payload: payload,
  };
};
export const setMfaOtpFlow = (payload) => {
  return {
    type: OTP_ENABLED,
    payload: payload,
  };
};

export const authenticateLoginOtp = (loginResponse, history) => {
  return {
    type: AUTHENTICATE_LOGIN_OTP,
    payload: { loginResponse, history },
  };
};

export const authenticateSsoCallback = (loginResponse, history) => {
  return {
    type: SSO_CALLBACK,
    payload: { loginResponse, history },
  };
};

export const loginSuccess = (user) => {
  return {
    type: LOGIN_SUCCESS,
    payload: user,
  };
};

export const logoutUser = (history) => {
  return {
    type: LOGOUT_USER,
    payload: { history },
  };
};

export const logoutUserSuccess = () => {
  return {
    type: LOGOUT_USER_SUCCESS,
    payload: {},
  };
};

export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};
