export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const MFA_ENABLED = "MFA_ENABLED";
export const OTP_ENABLED = "OTP_ENABLED";
export const AUTHENTICATE_LOGIN_OTP = "AUTHENTICATE_LOGIN_OTP";
export const SSO_CALLBACK = "SSO_CALLBACK";

export const LOGOUT_USER = "LOGOUT_USER";
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS";
export const API_ERROR = "LOGIN_API_ERROR";
