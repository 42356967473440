import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Alert, Row, Col, CardBody, Card, Container } from "reactstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { IconButton, Input, InputAdornment } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { resetPassword } from "../../store/dashboard/orchestractor";

import newLogoDark from "../../assets/images/logo/Ilumn Coloured Logo.png";
import { setLoadingState } from "../../store/actions";

export const ResetPassword = () => {
  const title = "Reset Password";
  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/;
  const defaultFormData = {
    newPassword: "",
    confirmPassword: "",
  };
  const defaultErrorMsg = { msg: "", color: "danger" };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const [errorMsg, setErrorMsg] = useState(defaultErrorMsg);
  const [formData, setFormData] = useState(defaultFormData);
  const [newPassErrorMsg, setNewPassErrorMsg] = useState("");
  const [confirmPassErrorMsg, setConfirmPassErrorMsg] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  useEffect(() => {
    document.title = title;
    resetFormData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetFormData = () => {
    setFormData(defaultFormData);
    setNewPassErrorMsg("");
    setConfirmPassErrorMsg("");
    setShowNewPassword(false);
    setShowConfirmPassword(false);
    dispatch(setLoadingState(false));
  };
  const handleNewPasswordChange = (event) => {
    const { value } = event?.target || {};

    if (value) {
      setFormData({ ...formData, newPassword: value });
      setErrorMsg(defaultErrorMsg);
      setNewPassErrorMsg("");
    } else if (!value) {
      setFormData({ ...formData, newPassword: "" });
      setErrorMsg(defaultErrorMsg);
      setNewPassErrorMsg("");
    }
  };
  const handleConfirmPasswordChange = (event) => {
    const { value } = event?.target || {};

    if (value) {
      setFormData({ ...formData, confirmPassword: value });
      setErrorMsg(defaultErrorMsg);
      setConfirmPassErrorMsg("");
    } else if (!value) {
      setFormData({ ...formData, confirmPassword: "" });
      setErrorMsg(defaultErrorMsg);
      setConfirmPassErrorMsg("");
    }
  };
  const validateForm = () => {
    if (!formData?.newPassword?.trim()) {
      setErrorMsg(defaultErrorMsg);
      setNewPassErrorMsg("Please enter new password.");
      return false;
    } else if (!passwordRegex.test(formData?.newPassword)) {
      setNewPassErrorMsg(
        "Password must be between 8-16 characters, include 1 lowercase, 1 uppercase, 1 number, and 1 special character."
      );
      return;
    }
    if (!formData?.confirmPassword?.trim()) {
      setErrorMsg(defaultErrorMsg);
      setConfirmPassErrorMsg("Please enter conform password.");
      return false;
    } else if (!passwordRegex.test(formData?.confirmPassword)) {
      setNewPassErrorMsg(
        "Password must be between 8-16 characters, include 1 lowercase, 1 uppercase, 1 number, and 1 special character."
      );
      return;
    }

    if (formData?.newPassword?.trim() !== formData?.confirmPassword?.trim()) {
      setNewPassErrorMsg("");
      setConfirmPassErrorMsg("");
      setErrorMsg({ msg: "Passwords do NOT match!", color: "danger" });
      return false;
    }

    return true;
  };
  const handleCreatePasswordClick = async () => {
    try {
      if (validateForm()) {
        const { token } = params || {};
        if (token) {
          dispatch(setLoadingState(true));
          const requestPayload = {
            password: formData.confirmPassword,
          };
          const response = await resetPassword(requestPayload, token);
          if (response?.data) {
            resetFormData();
            dispatch(setLoadingState(false));
            setErrorMsg({
              msg: "Password created successfully, redirecting to login",
              color: "success",
            });
            setTimeout(() => {
              navigate("/login");
            }, 2000);
          } else {
            setErrorMsg({ msg: "Some error occur", color: "danger" });
            dispatch(setLoadingState(false));
          }
        } else {
          setErrorMsg({ msg: "Some error occur", color: "danger" });
          dispatch(setLoadingState(false));
        }
      }
    } catch (error) {
      setErrorMsg({ msg: "Some error occur", color: "danger" });
      dispatch(setLoadingState(false));
    }
  };

  const handleClickNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleClickConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  // Password criteria checking
  const isLengthValid =
    formData?.newPassword?.length >= 8 && formData?.newPassword?.length <= 16;
  const hasLowerCase = /[a-z]/.test(formData?.newPassword);
  const hasUpperCase = /[A-Z]/.test(formData?.newPassword);
  const hasNumber = /\d/.test(formData?.newPassword);
  const hasSpecialChar = /[@$!%*?&]/.test(formData?.newPassword);

  return (
    <div className="account-pages my-4">
      <Container>
        <Row className="justify-content-center">
          <Col lg={6} md={8} xl={4} className="mt-3">
            <Card>
              <CardBody style={{ padding: 30 }}>
                <div className="text-center">
                  <Link to="/">
                    <img src={newLogoDark} alt="" height="40" />
                  </Link>
                </div>
                {errorMsg?.msg && (
                  <div
                    style={{
                      marginTop: "1rem",
                    }}
                  >
                    <Alert color={errorMsg?.color || "danger"} fade={false}>
                      <div>{errorMsg?.msg}</div>
                    </Alert>
                  </div>
                )}
                <h4 className="font-size-18 text-muted mt-2 text-center">
                  Reset Password
                </h4>
                {/* Password Criteria Hint */}
                {/* {isPasswordValid ? (
                  <></>
                ) : ( */}
                <div style={{ marginTop: 10 }}>
                  <ul>
                    <li style={{ color: hasLowerCase ? "green" : "black" }}>
                      At least 1 lowercase letter
                    </li>
                    <li style={{ color: hasUpperCase ? "green" : "black" }}>
                      At least 1 uppercase letter
                    </li>
                    <li style={{ color: hasNumber ? "green" : "black" }}>
                      At least 1 number
                    </li>
                    <li style={{ color: hasSpecialChar ? "green" : "black" }}>
                      At least 1 special character (@, $, !, %, etc.)
                    </li>
                    <li style={{ color: isLengthValid ? "green" : "black" }}>
                      Between 8 and 16 characters
                    </li>
                  </ul>
                </div>
                {/* )} */}
                <div>
                  <Input
                    name="newPassword"
                    aria-labelledby="newPassword"
                    className="form-control"
                    style={{ height: 40 }}
                    variant="outlined"
                    placeholder="New Password"
                    inputProps={{
                      maxLength: 16,
                    }}
                    onChange={handleNewPasswordChange}
                    onBlur={() => {}}
                    value={formData?.newPassword}
                    type={showNewPassword ? "text" : "password"}
                    endAdornment={
                      <>
                        {formData?.newPassword ? (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={handleClickNewPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showNewPassword ? (
                                <VisibilityIcon />
                              ) : (
                                <VisibilityOffIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ) : (
                          <></>
                        )}
                      </>
                    }
                  />
                </div>
                {newPassErrorMsg ? (
                  <span type="invalid" style={{ color: "red" }}>
                    {newPassErrorMsg}
                  </span>
                ) : (
                  <></>
                )}
                <div style={{ marginTop: 10 }}>
                  <Input
                    name="confirmPassword"
                    aria-labelledby="confirmPassword"
                    className="form-control"
                    style={{ height: 40 }}
                    variant="outlined"
                    placeholder="Confirm Password"
                    inputProps={{
                      maxLength: 16,
                    }}
                    onChange={handleConfirmPasswordChange}
                    onBlur={() => {}}
                    value={formData?.confirmPassword}
                    type={showConfirmPassword ? "text" : "password"}
                    endAdornment={
                      <>
                        {formData?.confirmPassword ? (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={handleClickConfirmPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showConfirmPassword ? (
                                <VisibilityIcon />
                              ) : (
                                <VisibilityOffIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ) : (
                          <></>
                        )}
                      </>
                    }
                  />
                </div>
                {confirmPassErrorMsg ? (
                  <span type="invalid" style={{ color: "red" }}>
                    {confirmPassErrorMsg}
                  </span>
                ) : (
                  <></>
                )}
                <div className="d-grid mt-2">
                  <button
                    className="btn btn-primary waves-effect waves-light"
                    type="submit"
                    onClick={handleCreatePasswordClick}
                  >
                    Submit
                  </button>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
