import React, { useEffect } from "react";
import { Link } from "react-router-dom";

export const SuccessMFA = () => {
  useEffect(() => {
    setTimeout(() => {
      handleLoginRedirect();
    }, 6000);
  }, []);

  const handleLoginRedirect = (event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    window.location.reload();
  };

  return (
    <div className="step-3">
      <div
        style={{
          marginTop: "1rem",
        }}
      >
        <p
          style={{
            fontWeight: "bold",
            fontSize: 18,
          }}
        >
          MFA Enabled
        </p>
        <p>We have successfully enabled MFA for your logicacloud account</p>
        <div style={{}}>
          <span>{"Redirecting to login 5s, if not "}</span>
          <Link
            to="/"
            style={{ fontWeight: "bold" }}
            onClick={handleLoginRedirect}
          >
            click here?
          </Link>
        </div>
      </div>
    </div>
  );
};
