import PropTypes from "prop-types";
import React from "react";
import { Alert } from "reactstrap";

export const EnableMFA = (props) => {
  const {
    title,
    errorWithGenerateQRO,
    handleEnableMFAClick,
    handleSkipMFAClick,
  } = props;

  return (
    <div className="step-1">
      {errorWithGenerateQRO && (
        <div
          style={{
            marginTop: "1rem",
          }}
        >
          <Alert color="danger" fade={false}>
            <div>{errorWithGenerateQRO}</div>
          </Alert>
        </div>
      )}
      <div>
        <p
          style={{
            fontWeight: "bold",
            fontSize: 18,
            marginTop: "1rem",
          }}
        >
          {title}
        </p>
      </div>
      <div>
        <p>
          Enable Multi-Factor Authentication (MFA) to add an extra layer of
          security and keep your account safe from unauthorized access.
        </p>
      </div>
      <div className="d-grid mt-4">
        <button
          className="btn btn-primary waves-effect waves-light"
          type="submit"
          onClick={handleEnableMFAClick}
        >
          {title}
        </button>
      </div>
      <div className="d-grid mt-4">
        <button
          className="btn btn-primary waves-effect waves-light"
          type="submit"
          onClick={handleSkipMFAClick}
        >
          {"Skip two-step Verification"}
        </button>
      </div>
    </div>
  );
};

EnableMFA.propTypes = {
  title: PropTypes.string,
  errorWithGenerateQRO: PropTypes.any,
  handleEnableMFAClick: PropTypes.any,
  handleSkipMFAClick: PropTypes.any,
};
