import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { authenticateSsoCallback, setLoadingState } from "../../store/actions";
import { ssoCallback } from "../../store/dashboard/orchestractor";

export const SSOCallback = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.search) {
      ssoCallbackAPICall(location.search);
    }
    console.log(location.search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ssoCallbackAPICall = async (queryParams) => {
    try {
      dispatch(setLoadingState(true));
      const response = await ssoCallback(queryParams);
      if (response?.data && response?.data?.user) {
        dispatch(authenticateSsoCallback(response?.data, navigate));
        dispatch(setLoadingState(false));
      } else {
        dispatch(setLoadingState(false));
        navigate("/login");
      }
    } catch (error) {
      console.error(error);
      dispatch(setLoadingState(false));
      navigate("/login");
    }
  };

  return <></>;
};
