import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import styled from "styled-components";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import {
  copy,
  getDetailsForSelectedRecord,
  getTenantList,
  move,
  reorder,
} from "./commonFunc";
import { fetchSuiteRunListAPI } from "../../../store/dashboard/orchestractor";
import dragIcon from "../../../assets/svg/drag_icon.svg";
import closeIcon from "../../../assets/svg/Close_icon.svg";

const Content = styled.div`
  width: 80%;
  height: 100vh;
  background: #fafaff;
`;
const Item = styled.div`
  display: flex;
  user-select: none;
  padding: 0.5rem;
  align-items: flex-start;
  align-content: flex-start;
  line-height: 1.5;
  border-radius: 3px;
  background: #fff;
`;
const DropItem = styled.div`
  display: flex;
  user-select: none;
  padding: 0.5rem;
  align-items: flex-start;
  align-content: flex-start;
  line-height: 1.5;
  border-radius: 3px;
  background: #fafaff;
  margin-bottom: 5px;
`;
const Handle = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  user-select: none;
  padding: 0.5rem;
  line-height: 1.5;
  border-radius: 3px 0 0 3px;
  background: #fff;
  border-right: 1px solid #ddd;
  color: #000;
`;
const List = styled.div`
  border: 1px
    ${(props) => (props.isDraggingOver ? "dashed #000" : "solid #ddd")};
  background: #fff;
  padding: 0.5rem 0.5rem 0;
  border-radius: 3px;
  font-family: sans-serif;
`;
const Kiosk = styled(List)`
  top: 0;
  right: 0;
  bottom: 0;
  width: 20%;
`;
const Container = styled(List)`
  background: #ccc;
  min-height: 50px;
  width: 300px;
  border: 1px dashed #000000;
`;
const Notice = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  padding: 0.5rem;
  margin: 0 0.5rem 0.5rem;
  border: 1px solid transparent;
  line-height: 1.5;
  color: #aaa;
`;

const SelectedItem = ({
  item = null,
  dateTime = null,
  type = "",
  label = "",
  bottomText = "",
  handleEditSteps = () => {},
}) => {
  return (
    <>
      {item && (
        <div className="selected-item-div">
          <div
            style={
              type === "tenant-1" || type === "tenant-2"
                ? { display: "flex", alignItems: "center", marginTop: "2px" }
                : {}
            }
          >
            <Typography variant="body2">{label}</Typography>
            {type === "tenant-1" || type === "tenant-2" ? (
              <span
                style={{
                  marginLeft: "10px",
                  background: "#E9FFE8",
                  border: "1px solid #04BB00",
                  borderRadius: "20px",
                  padding: "0px 5px",
                }}
              >
                <CheckCircleIcon sx={{ fontSize: 12, color: "#04BB00" }} />
                <span
                  style={{
                    color: "#04BB00",
                    fontSize: "12px",
                  }}
                >
                  Connected
                </span>
              </span>
            ) : null}
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography>{item?.content}</Typography>
            <IconButton
              aria-label="edit"
              size="small"
              onClick={() => handleEditSteps(item, type, dateTime)}
            >
              {type === "category" ? (
                <AddOutlinedIcon sx={{ fontSize: 14, color: "#4010FF" }} />
              ) : (
                <ModeEditOutlinedIcon sx={{ fontSize: 14, color: "#4010FF" }} />
              )}
            </IconButton>
          </div>
        </div>
      )}
      {dateTime && (
        <div
          className="selected-item-div"
          style={{
            position: "relative",
            borderStyle: "solid",
            minHeight: "75px",
            padding: 0,
          }}
        >
          <Typography
            variant="body2"
            sx={{
              position: "absolute",
              top: -10,
              left: 5,
              backgroundColor: "white",
              padding: "0 4px",
            }}
          >
            {label}
          </Typography>
          {/* <Typography variant="body2">{label}</Typography> */}
          <div className="selected-item-div-1">
            <Typography>{dateTime?.date}</Typography>
            <div className="selected-item-div-2">
              <Typography marginLeft={1}>{dateTime?.time}</Typography>
              <Typography marginLeft={1}>{dateTime?.timeType}</Typography>
            </div>
          </div>
          <Typography
            variant="body2"
            style={{
              marginTop: "18px",
              width: "100%",
              background: "#ECECEC",
              border: "1px solid #D9D9D9",
            }}
          >
            {"Auto-set to today, but you can change it."}
          </Typography>
        </div>
      )}
    </>
  );
};

const Create = (props) => {
  const defaultData = {
    tenant: null,
    dateTime: null,
    functionalArea: null,
    category: null,
    testSuite: null,
  };
  const formattedDateTime = moment().format("MM/DD/YYYY hh:mm A");

  const [rawData, setRawData] = useState(null);
  const [currentSelection, setCurrentSelection] = useState(null);
  const [draggableList, setDraggableList] = useState([]);
  const [multiSelectionData, setMultiSelectionData] = useState({});
  const [state, setState] = useState({
    category: [],
  });
  const [selectionDetails, setSelectionDetails] = useState(null);
  const [isEditEnable, setIsEditEnable] = useState(false);

  const tenants = useSelector((state) => state.dashboardData.tenants || []);

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchSuiteRunListAPI();
      setRawData(data);
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (rawData?.suiteScriptListByFA?.length > 0 && tenants?.length > 0) {
      setCurrentSelection("tenant");
      setDraggableList(getTenantList(tenants));
      setSelectionDetails(defaultData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rawData, tenants]);

  useEffect(() => {
    console.log("useEffect", multiSelectionData, currentSelection);
    const existingData = multiSelectionData[currentSelection] || [];
    if (existingData?.length) {
      setState({ [currentSelection]: [...existingData] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [multiSelectionData]);

  const onDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    const selectionItem = copy(
      draggableList,
      state[destination.droppableId],
      source,
      destination
    );
    // switch (source.droppableId) {
    //   case destination.droppableId:
    //     this.setState({
    //       [destination.droppableId]: reorder(
    //         this.state[source.droppableId],
    //         source.index,
    //         destination.index
    //       ),
    //     });
    //     break;
    //   case "draggableList":
    //     this.setState({
    //       [destination.droppableId]: copy(
    //         draggableList,
    //         this.state[destination.droppableId],
    //         source,
    //         destination
    //       ),
    //     });
    //     break;
    //   default:
    //     this.setState(
    //       move(
    //         this.state[source.droppableId],
    //         this.state[destination.droppableId],
    //         source,
    //         destination
    //       )
    //     );
    //     break;
    // }

    if (selectionItem?.length) {
      const record = selectionItem[0];

      if (currentSelection === "tenant") {
        const existingItem = selectionDetails[currentSelection];
        let tenantSelection = [];
        if (existingItem?.length) {
          tenantSelection = [...existingItem, record];
        } else {
          tenantSelection = [record];
        }
        let finalSelection = { [currentSelection]: [record] };
        if (tenantSelection?.length === 2) {
          const dateDetails = formattedDateTime.split(" ");
          finalSelection = {
            [currentSelection]: tenantSelection,
            dateTime: {
              date: dateDetails[0],
              time: dateDetails[1],
              timeType: dateDetails[2],
            },
          };
          const list = getDetailsForSelectedRecord(
            rawData,
            record,
            "functionalArea"
          );
          setCurrentSelection("functionalArea");
          setDraggableList(list);
        }
        setSelectionDetails({
          ...selectionDetails,
          ...finalSelection,
        });
        setMultiSelectionData({ category: [] });
        setIsEditEnable(false);
      } else if (currentSelection === "functionalArea") {
        let finalSelection = { [currentSelection]: record };
        const list = getDetailsForSelectedRecord(rawData, record, "category");
        setCurrentSelection("category");
        setDraggableList(list);
        setSelectionDetails({
          ...selectionDetails,
          ...finalSelection,
        });
        setMultiSelectionData({ category: [] });
        setIsEditEnable(false);
      } else if (currentSelection === "category") {
        let finalSelection = { [currentSelection]: record };
        const existingData = multiSelectionData[currentSelection] || [];
        const list = getDetailsForSelectedRecord(
          rawData,
          record,
          "testSuite",
          selectionDetails?.functionalArea
        );
        if (isEditEnable) {
          //
        } else {
          setCurrentSelection("testSuite");
          setDraggableList(list);
          setSelectionDetails({
            ...selectionDetails,
            ...finalSelection,
          });
        }
        // console.log(currentSelection, record);
        setMultiSelectionData({
          ...multiSelectionData,
          [currentSelection]: [...existingData, record],
        });
      } else if (currentSelection === "testSuite") {
        let finalSelection = { [currentSelection]: record };
        const existingData = multiSelectionData[currentSelection] || [];
        setSelectionDetails({
          ...selectionDetails,
          ...finalSelection,
        });
        setMultiSelectionData({
          ...multiSelectionData,
          [currentSelection]: [...existingData, record],
        });
      }
    }
  };

  const getRightPanelTitle = () => {
    const titleObject = {
      tenant: "Tenant List",
      functionalArea: "Functional Area",
      category: "Category",
      testSuite: "Test Suite",
    };

    return titleObject[currentSelection];
  };

  const handleEditSteps = (rec, type = "", dateTime) => {
    // console.log(type);
    if (type) {
      if (type === "tenant-1") {
        setCurrentSelection("tenant");
        setDraggableList(getTenantList(tenants));
        setSelectionDetails(defaultData);
        setState({ category: [] });
        setIsEditEnable(true);
      } else if (type === "tenant-2") {
        const tenantData = selectionDetails?.tenant || [];
        setSelectionDetails({
          ...selectionDetails,
          tenant: [tenantData[0]],
          functionalArea: null,
          category: null,
          testSuite: null,
        });
        setCurrentSelection("tenant");
        setDraggableList(getTenantList(tenants));
        setState({ category: [] });
        setIsEditEnable(true);
      } else if (type === "functionalArea") {
        const list = getDetailsForSelectedRecord(
          rawData,
          selectionDetails[type],
          type
        );
        setCurrentSelection("functionalArea");
        setDraggableList(list);
        setSelectionDetails({
          ...selectionDetails,
          category: null,
          testSuite: null,
          [type]: null,
        });
        setState({ category: [] });
        setIsEditEnable(true);
      } else if (type === "category") {
        const list = getDetailsForSelectedRecord(
          rawData,
          selectionDetails?.functionalArea,
          type
        );
        const existingData = multiSelectionData[type] || [];
        setCurrentSelection("category");
        setDraggableList(list);
        setSelectionDetails({
          ...selectionDetails,
          testSuite: null,
          [type]: null,
        });
        setState({ [type]: [...existingData] });
        setIsEditEnable(true);
      } else if (type === "testSuite") {
        const list = getDetailsForSelectedRecord(
          rawData,
          selectionDetails?.category,
          type,
          selectionDetails?.functionalArea
        );
        const existingData = multiSelectionData[type] || [];
        setCurrentSelection("testSuite");
        setDraggableList(list);
        setSelectionDetails({ ...selectionDetails, [type]: null });
        setState({ [type]: [...existingData] });
        setIsEditEnable(true);
      } else {
        console.log("Else...");
        setIsEditEnable(false);
      }
    }
  };
  const handleAddMoreSave = () => {
    console.log("handleAddMoreSave ===> ", currentSelection, state);
  };
  const handleRemoveSelectedItem = () => {
    console.log(
      "handleRemoveSelectedItem ===> ",
      currentSelection,
      state,
      multiSelectionData
    );
  };

  console.log(state);
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div
        style={{
          display: "flex",
          alignItems: "start",
        }}
      >
        <Content>
          {selectionDetails?.tenant?.length === 2 ? (
            <>
              <div className="wrapper-div margin-top">
                {selectionDetails?.tenant?.map((rec, index) => (
                  <SelectedItem
                    item={rec}
                    type={"tenant-" + (index + 1)}
                    label={index === 0 ? "First Tenant" : "Second Tenant"}
                    handleEditSteps={handleEditSteps}
                  />
                ))}
              </div>
              <div className="wrapper-div">
                <div className="vertical-line"></div>
              </div>

              {selectionDetails?.dateTime ? (
                <>
                  <div className="wrapper-div">
                    <SelectedItem
                      dateTime={selectionDetails?.dateTime}
                      type={"dateTime"}
                      label={"As of date & time"}
                      bottomText={"Auto set to today, but you can change it."}
                      handleEditSteps={handleEditSteps}
                    />
                  </div>
                  <div className="wrapper-div">
                    <div className="vertical-line"></div>
                  </div>
                </>
              ) : (
                <></>
              )}

              {selectionDetails?.functionalArea ? (
                <>
                  <div className="wrapper-div">
                    <SelectedItem
                      type={"functionalArea"}
                      item={selectionDetails?.functionalArea}
                      label={"Functional Area"}
                      handleEditSteps={handleEditSteps}
                    />
                  </div>
                  <div className="wrapper-div">
                    <div className="vertical-line"></div>
                  </div>
                </>
              ) : (
                <></>
              )}

              {selectionDetails?.category ? (
                <>
                  <div className="wrapper-div">
                    <SelectedItem
                      type={"category"}
                      item={selectionDetails?.category}
                      label={"Category"}
                      handleEditSteps={handleEditSteps}
                    />
                  </div>
                  <div className="wrapper-div">
                    <div className="vertical-line"></div>
                  </div>
                </>
              ) : (
                <></>
              )}

              {/* {selectionDetails?.testSuite ? (
                <div className="wrapper-div">
                  <SelectedItem
                    type={"testSuite"}
                    item={selectionDetails?.testSuite}
                    label={"Test Suite (Multi Select)"}
                    handleEditSteps={handleEditSteps}
                  />
                </div>
              ) : (
                <></>
              )} */}
            </>
          ) : (
            <></>
          )}
          <div
            className={`wrapper-div ${
              selectionDetails?.tenant?.length === 2 ? "" : "margin-top"
            }`}
            // style={selectionDetails?.testSuite ? { display: "none" } : {}}
          >
            {selectionDetails?.tenant?.length === 1 ? (
              selectionDetails?.tenant?.map((rec) => (
                <SelectedItem
                  item={rec}
                  type={"tenant-1"}
                  label={"First Tenant"}
                  handleEditSteps={handleEditSteps}
                />
              ))
            ) : (
              <></>
            )}
            <div>
              {Object.keys(state).map((list, i) => {
                return (
                  <Droppable
                    key={list}
                    droppableId={list}
                    isDropDisabled={false}
                  >
                    {(provided, snapshot) => (
                      <Container
                        ref={provided.innerRef}
                        isDraggingOver={snapshot.isDraggingOver}
                      >
                        {state[list].length &&
                        (currentSelection === "category" ||
                          currentSelection === "testSuite") ? (
                          <Box
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "end",
                            }}
                          >
                            <IconButton
                              aria-label="edit"
                              size="small"
                              onClick={handleAddMoreSave}
                            >
                              <DoneOutlinedIcon
                                sx={{ fontSize: 14, color: "#4010FF" }}
                              />
                            </IconButton>
                          </Box>
                        ) : null}
                        {state[list].length
                          ? state[list].map((item, index) => (
                              <>
                                <Draggable
                                  key={item.id}
                                  draggableId={item.id}
                                  index={index}
                                  isDragDisabled={true}
                                >
                                  {(provided, snapshot) => (
                                    <DropItem
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      isDragging={snapshot.isDragging}
                                      style={provided.draggableProps.style}
                                    >
                                      <Box
                                        width={"100%"}
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "space-between",
                                          padding: "0px 5px",
                                        }}
                                      >
                                        {item.content}
                                        <IconButton
                                          aria-label="edit"
                                          size="small"
                                          onClick={handleRemoveSelectedItem}
                                        >
                                          <CloseOutlinedIcon
                                            sx={{
                                              fontSize: 14,
                                              color: "#4010FF",
                                            }}
                                          />
                                        </IconButton>
                                      </Box>
                                    </DropItem>
                                  )}
                                </Draggable>
                              </>
                            ))
                          : !provided.placeholder && (
                              <Notice>Drop items here</Notice>
                            )}
                        {provided.placeholder}
                      </Container>
                    )}
                  </Droppable>
                );
              })}
            </div>
          </div>
        </Content>
        <Droppable
          droppableId="draggableList"
          direction="vertical"
          isDropDisabled={true}
        >
          {(provided) => (
            <Kiosk ref={provided.innerRef} {...provided.droppableProps}>
              <div>
                <div style={{ fontWeight: "700", fontSize: "16px" }}>
                  {getRightPanelTitle()}
                </div>
                <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                  <FormControl
                    sx={{ mt: 1, mb: 1, width: "100%", height: "40px" }}
                    variant="filled"
                  >
                    <InputLabel htmlFor="outlined-adornment-password"></InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      type={"text"}
                      style={{ height: "40px", backgroundColor: "#EBEBEB" }}
                      placeholder={`Search ${getRightPanelTitle()}`}
                      startAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label={"search-from-list"}
                            onClick={() => {}}
                            onMouseDown={() => {}}
                            onMouseUp={() => {}}
                            edge="start"
                          >
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                      label=""
                    />
                  </FormControl>
                </Box>
                <div
                  style={{
                    height: "88vh",
                    overflowY: "auto",
                  }}
                >
                  {draggableList.map((item, index) => (
                    <Draggable
                      key={item.id}
                      draggableId={item.id}
                      index={index}
                    >
                      {(provided) => (
                        <>
                          <Item
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: "10px",
                              }}
                            >
                              <img src={dragIcon} alt="drag-icon" />
                              {item.content}
                            </Box>
                          </Item>
                        </>
                      )}
                    </Draggable>
                  ))}
                </div>
              </div>
            </Kiosk>
          )}
        </Droppable>
      </div>
    </DragDropContext>
  );
};

export default Create;
