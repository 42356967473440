import axios from "axios";
let UI_URL = window.location.host;
UI_URL = UI_URL ? `https://api-${UI_URL}` : null;
const API_Base_Url = UI_URL || process.env.REACT_APP_API_BASE_URL;

export const logoutMyUser = () => {
  localStorage.clear();
  window.location.replace(window.location.origin, true);
};
export const getAPI = async (url) => {
  const access_token = localStorage.getItem("access_token");
  const response = await fetch(`${API_Base_Url}${url}`, {
    method: "GET",
    headers: {
      Authorization: access_token || "",
    },
  });

  if (!response.ok) {
    if (response.status === 401) {
      logoutMyUser();
    }
    throw new Error("API request failed");
  }

  const data = await response.json();
  return data;
};
export const postAPI = async (url, payload) => {
  const access_token = localStorage.getItem("access_token");
  const response = await fetch(`${API_Base_Url}${url}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: access_token || "",
    },
    body: JSON.stringify(payload),
  });

  if (!response.ok) {
    if (response.status === 401) {
      logoutMyUser();
    }
    if (response.status === 500) {
      const data = await response.json();

      return data;
    }
    throw new Error("API request failed");
  }

  if (response?.headers?.get("Authorization")) {
    localStorage.setItem(
      "access_token",
      response?.headers?.get("Authorization")
    );
  }
  if (response?.headers?.get("X-Request-Id")) {
    console.info(response?.headers?.get("X-Request-Id"));
  }

  const data = await response.json();
  return data;
};
export const putAPI = async (url, payload) => {
  const access_token = localStorage.getItem("access_token");
  const response = await fetch(`${API_Base_Url}${url}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: access_token || "",
    },
    body: JSON.stringify(payload),
  });

  if (!response.ok) {
    if (response.status === 401) {
      logoutMyUser();
    }
    throw new Error("API request failed");
  }

  if (response?.headers?.get("Authorization")) {
    localStorage.setItem(
      "access_token",
      response?.headers?.get("Authorization")
    );
  }
  if (response?.headers?.get("X-Request-Id")) {
    console.info(response?.headers?.get("X-Request-Id"));
  }

  const data = await response.json();
  return data;
};
export const deleteAPI = async (url) => {
  const access_token = localStorage.getItem("access_token");
  const response = await fetch(`${API_Base_Url}${url}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: access_token || "",
    },
  });

  if (!response.ok) {
    if (response.status === 401) {
      logoutMyUser();
    }
    throw new Error("API request failed");
  }

  if (response?.headers?.get("Authorization")) {
    localStorage.setItem(
      "access_token",
      response?.headers?.get("Authorization")
    );
  }
  if (response?.headers?.get("X-Request-Id")) {
    console.info(response?.headers?.get("X-Request-Id"));
  }

  const data = response;
  return data;
};
export const generateServiceNow = async (newRecord) => {
  try {
    const apiEndPoint = "/api/now/table/incident";
    const response = await axios.post(apiEndPoint, newRecord, {
      auth: {
        username: process.env.REACT_APP_SERVICE_NOW_NAME || "",
        password: process.env.REACT_APP_SERVICE_NOW_P || "",
      },
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response?.status !== 201) {
      logoutMyUser();
      throw new Error("API request failed");
    }

    const data = await response?.data;

    return data;
  } catch (error) {
    return null;
  }
};
export const fileUploadAPI = async (url, formData) => {
  try {
    const access_token = localStorage.getItem("access_token");
    const response = await axios.post(`${API_Base_Url}${url}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: access_token || "",
      },
    });
    return response;
  } catch (error) {
    return null;
  }
};
export const downloadFileAPI = async (url) => {
  const access_token = localStorage.getItem("access_token");
  const response = await axios.get(`${API_Base_Url}${url}`, {
    method: "GET",
    headers: {
      Authorization: access_token || "",
    },
  });

  return response;
};
export const generateQROImage = async (url, formData, type = "") => {
  try {
    const access_token = localStorage.getItem("access_token");
    const response = await axios.post(`${API_Base_Url}${url}`, formData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: access_token || "",
      },
    });
    if (type === "verify") {
      if (response?.headers?.get("Authorization")) {
        localStorage.setItem(
          "access_token",
          response?.headers?.get("Authorization")
        );
      }
    }
    return response;
  } catch (error) {
    return null;
  }
};
export const resetPasswordPostAPI = async (url, formData, token) => {
  try {
    const response = await axios.post(`${API_Base_Url}${url}`, formData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: token || "",
      },
    });

    return response;
  } catch (error) {
    return null;
  }
};
export const forgotPasswordPostAPI = async (url, formData) => {
  try {
    const response = await axios.get(`${API_Base_Url}${url}?${formData}`, {
      method: "GET",
    });

    return response;
  } catch (error) {
    return null;
  }
};

export const ssoCallbackGetAPI = async (url, formData) => {
  try {
    const response = await fetch(`${API_Base_Url}${url}${formData}`, {
      method: "GET",
    });

    if (response?.headers?.get("Authorization")) {
      localStorage.setItem(
        "access_token",
        response?.headers?.get("Authorization")
      );
    }
    if (response?.headers?.get("X-Request-Id")) {
      console.info(response?.headers?.get("X-Request-Id"));
    }

    return response;
  } catch (error) {
    return null;
  }
};
