import React from "react";
import Login from "../Pages/Authentication/Login";
import Logout from "../Pages/Authentication/Logout";
import {
  adminRoutes,
  commonRoutes,
  testerRoutes,
  viewerRoutes,
  privilegedRoutes,
} from "../Pages/accessSettingsMenu";
import { ResetPassword } from "../Pages/Authentication/ResetPassword";
import { SSOCallback } from "../Pages/Authentication/SSOCallback";

// Getting UserProfile Object from Local Storage....
const storedAuthUser = localStorage.getItem("authUser");
const obj = storedAuthUser ? JSON.parse(storedAuthUser) : null;

// Getting Admin Roles Flag from Storage
const adminRoleFlag = obj?.user?.adminRole;

// Getting Testing Roles Flag from Storage
const testerRoleFlag = obj?.user?.testerRole;

// Getting Viewer Roles Flag from Storage
const viewerRoleFlag = obj?.user?.viewerRole;
const privilegedRoleFlag = obj?.user?.privilegeUserRole;

const authProtectedRoutes = [
  ...(adminRoleFlag ? adminRoutes : []),
  ...(testerRoleFlag ? testerRoutes : []),
  ...(viewerRoleFlag ? viewerRoutes : []),
  ...(privilegedRoleFlag ? privilegedRoutes : []),
  ...(adminRoleFlag ? [] : commonRoutes),
];

const publicRoutes = [
  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/reset-pwd-link/:token", component: <ResetPassword /> },
  { path: "/auth/callback", component: <SSOCallback /> },
];

export { authProtectedRoutes, publicRoutes };
