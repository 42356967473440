import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  MFA_ENABLED,
  OTP_ENABLED,
  AUTHENTICATE_LOGIN_OTP,
  SSO_CALLBACK,
} from "./actionTypes";

const initialState = {
  error: "",
  loading: false,
  userLoggedIn: false,
  isMFAEnabled: false,
  isOTPEnabled: false,
};

const login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case LOGIN_SUCCESS:
      state = {
        ...state,
        loading: false,
        userLoggedIn: true,
      };
      break;
    case MFA_ENABLED:
      state = {
        ...state,
        isMFAEnabled: action.payload,
      };
      break;
    case OTP_ENABLED:
      state = {
        ...state,
        isOTPEnabled: action.payload,
      };
      break;
    case AUTHENTICATE_LOGIN_OTP:
      state = {
        ...state,
        loading: true,
      };
      break;
    case SSO_CALLBACK:
      state = {
        ...state,
        loading: true,
      };
      break;
    case LOGOUT_USER:
      state = { ...state, isUserLogout: false };
      break;
    case LOGOUT_USER_SUCCESS:
      state = { ...state, isUserLogout: true, userLoggedIn: false };
      break;
    case API_ERROR:
      state = {
        ...state,
        error: action.payload,
        loading: false,
        isUserLogout: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default login;
