import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Input } from "@mui/material";
import { Link } from "react-router-dom";
import { Alert } from "reactstrap";

export const ScanMFA = (props) => {
  const { scannerImageBase64, errorMsg, setErrorMsg, handleOTPVerifyClick } =
    props;

  const [otpValue, setOTPValue] = useState("");
  const [otpErrorMsg, setOTPErrorMsg] = useState("");
  const [apiErrorMsg, setApiErrorMsg] = useState("");
  const [qROImage, setQroImage] = useState("");

  useEffect(() => {
    setOTPValue("");
    setOTPErrorMsg("");
  }, []);
  useEffect(() => {
    if (scannerImageBase64) {
      setQroImage(`data:image/png;base64, ${scannerImageBase64}`);
    }
  }, [scannerImageBase64]);

  const handleValueChange = (event) => {
    const { value } = event?.target || {};
    const numberPattern = /^[0-9]+$/;

    if (value && numberPattern.test(value)) {
      setOTPValue(value);
      setOTPErrorMsg("");
      setErrorMsg("");
    } else if (!value) {
      setOTPValue("");
      setOTPErrorMsg("");
      setErrorMsg("");
    }
  };

  const handleVerifyClick = (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (!otpValue) {
      setOTPErrorMsg("Please enter OTP");
      return;
    }
    if (otpValue?.length === 6) {
      setApiErrorMsg("");
      setErrorMsg("");
      handleOTPVerifyClick(otpValue);
    } else {
      setErrorMsg("");
      setApiErrorMsg("Please enter correct OTP");
    }
  };

  return (
    <div className="step-2">
      {(apiErrorMsg || errorMsg) && (
        <div
          style={{
            marginTop: "1rem",
          }}
        >
          <Alert color="danger" fade={false}>
            <div>{apiErrorMsg || errorMsg}</div>
          </Alert>
        </div>
      )}
      <div>
        <p
          style={{
            fontWeight: "bold",
            fontSize: 18,
            marginTop: "1rem",
          }}
        >
          1. Scan QR code
        </p>
        <p style={{ marginLeft: 20 }}>
          Scan this QA code using you Microsoft/Google authenticator add to
          generate a 6-digit verification code.
        </p>
        <div style={{ marginLeft: 20 }}>
          <Link to="/">
            <img src={qROImage} alt="" style={{ height: 150, width: 150 }} />
          </Link>
        </div>
      </div>
      <div>
        <p
          style={{
            fontWeight: "bold",
            fontSize: 18,
            marginTop: "1rem",
          }}
        >
          2. Enter verification code
        </p>
        <p style={{ marginLeft: 20 }}>
          Enter the code from your authenticator app. We'll remember your device
          for future logins.
        </p>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 10,
            marginLeft: 20,
          }}
        >
          <Input
            name="authCode"
            aria-labelledby="email"
            className="form-control"
            style={{ height: 40 }}
            variant="outlined"
            placeholder="Enter 6 digit code"
            type="text"
            inputProps={{
              maxLength: 6,
            }}
            onChange={handleValueChange}
            onBlur={() => {}}
            value={otpValue}
          />
          <button
            className="btn btn-primary waves-effect waves-light"
            type="submit"
            onClick={handleVerifyClick}
          >
            Verify
          </button>
        </div>

        {otpErrorMsg ? (
          <span type="invalid" style={{ marginLeft: 20, color: "red" }}>
            {otpErrorMsg}
          </span>
        ) : (
          <></>
        )}
        <div
          style={{
            gap: 10,
            marginLeft: 20,
            marginTop: 15,
            fontWeight: "bold",
          }}
        >
          <Link to="/">Need help?</Link>
        </div>
      </div>
    </div>
  );
};

ScanMFA.propTypes = {
  scannerImageBase64: PropTypes.any,
  errorMsg: PropTypes.any,
  setErrorMsg: PropTypes.any,
  handleOTPVerifyClick: PropTypes.any,
};
