import { call, fork, put, takeEvery } from "redux-saga/effects";
// Login Redux States
import {
  AUTHENTICATE_LOGIN_OTP,
  LOGIN_USER,
  LOGOUT_USER,
  SSO_CALLBACK,
} from "./actionTypes";
import {
  apiError,
  loginSuccess,
  logoutUserSuccess,
  setMFAEnableFlow,
  setMfaOtpFlow,
} from "./actions";
//Include Both Helper File with needed methods
import { loginApi } from "../../dashboard/orchestractor";
import DashboardSaga from "../../dashboard/saga";
import { setLoadingState } from "../../actions";

function* verifyMfaOtp({ payload: { loginResponse, history } }) {
  try {
    yield put(setLoadingState(false));
    yield put(loginSuccess(loginResponse));
    yield put(setMFAEnableFlow(false));
    if (loginResponse?.user?.adminRole) {
      history("/manage-users");
    } else {
      yield fork(DashboardSaga);
      history("/home");
    }
  } catch (error) {
    yield put(setLoadingState(false));
    yield put(apiError("Login Failed"));
  }
}
function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(
      loginApi,
      user.email,
      user.password,
      user?.encrypted || false
    );
    if (response?.error) {
      yield put(setLoadingState(false));
      yield put(apiError("Login Failed"));
    } else if (response?.user) {
      if (response?.user?.redirectUrl) {
        window.open(`${response?.user?.redirectUrl}`, "_self");
      } else {
        if (response?.user?.isPasswordExpired) {
          let access_token = localStorage.getItem("access_token");
          access_token =
            access_token && access_token.startsWith("Bearer ")
              ? access_token.split("Bearer ")[1]
              : "";
          localStorage.setItem("authUser", JSON.stringify(response));
          history(`/reset-pwd-link/${access_token}`);
        } else {
          if (response?.user?.mfaEnabled) {
            // Need to open OTP Screen
            localStorage.setItem("authUser", JSON.stringify(response));
            yield put(setLoadingState(false));
            yield put(setMfaOtpFlow(true));
            yield put(setMFAEnableFlow(false));
          } else {
            localStorage.setItem("authUser", JSON.stringify(response));
            yield put(setLoadingState(false));
            yield put(setMFAEnableFlow(true));
            yield put(setMfaOtpFlow(false));
          }
        }
      }
    } else {
      yield put(setLoadingState(false));
      yield put(apiError("Login Failed"));
    }
  } catch (error) {
    yield put(setLoadingState(false));
    yield put(apiError("Login Failed"));
  }
}

function* logoutUser() {
  try {
    // localStorage.removeItem("authUser");
    localStorage.clear();

    yield put(logoutUserSuccess(LOGOUT_USER, true));
  } catch (error) {
    yield put(apiError(LOGOUT_USER, error));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeEvery(AUTHENTICATE_LOGIN_OTP, verifyMfaOtp);
  yield takeEvery(SSO_CALLBACK, verifyMfaOtp);
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;
